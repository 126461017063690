<template>
  <div>
    <p class="text-h6 flex-grow-1">{{ venue.name || "#" + venue.id }}</p>
    <v-chip
      color="primary"
      small
      :outlined="!venue.subject"
      class="mr-1 mb-1"
      >{{ venue.subject ? "Субъектная" : "Несубъектная" }}</v-chip
    >
    <v-chip v-if="venue.deleted" color="error" small class="mr-1 mb-1"
      >Удалена</v-chip
    >

    <option-row title="ID площадки:">{{ venue.id }}</option-row>

    <v-expansion-panels
      v-if="description && isDialog"
      flat
      class="black--text text-body-1 pa-0 mb-1"
    >
      <v-expansion-panel @change="isOpenDescription = !isOpenDescription">
        <v-expansion-panel-header class="text-body-1 d-block pa-0"
          ><template #actions>
            <div class="link">
              {{ isOpenDescription ? "Скрыть" : "Показать" }}
            </div> </template
          ><span class="secondary--text text--lighten-2 mr-1">Описание:</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="description" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <option-row v-if="venue.geodata.address" title="Адрес:">{{
      venue.geodata.address
    }}</option-row>
    <option-row title="Отображается на карте:">{{
      venue.show_on_map ? "Да" : "Нет"
    }}</option-row>
    <option-row title="Доступность площадки:">{{
      venue.unavailable ? "Недоступна" : "Доступна"
    }}</option-row>

    <template v-if="isDialog">
      <option-row v-if="venue.cover_url" title="Обложка:"
        ><a :href="venue.cover_url" target="_blank" rel="noopener noreferrer">
          Смотреть</a
        ></option-row
      >
      <option-row
        v-if="venue.navigation_image_url"
        title="Карта-схема расположения:"
        ><a
          :href="venue.navigation_image_url"
          target="_blank"
          rel="noopener noreferrer"
        >
          Смотреть</a
        ></option-row
      >
      <option-row v-if="venue.navigation_link" title="Ссылка на схему проезда:"
        ><a
          :href="venue.navigation_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Смотреть</a
        ></option-row
      >
      <option-row v-if="venue.navigation_link" title="Ссылка на схему проезда:"
        ><a
          :href="venue.navigation_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Смотреть</a
        ></option-row
      >
      <option-row v-if="venue.navigation_link" title="Ссылка на схему проезда:"
        ><a
          :href="venue.navigation_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Смотреть</a
        ></option-row
      >
      <v-expansion-panels
        v-if="navigationText"
        flat
        class="black--text text-body-1 pa-0 mb-1"
      >
        <v-expansion-panel @change="isOpenNavText = !isOpenNavText">
          <v-expansion-panel-header class="text-body-1 d-block pa-0"
            ><template #actions>
              <div class="link">
                {{ isOpenNavText ? "Скрыть" : "Показать" }}
              </div> </template
            ><span class="secondary--text text--lighten-2 mr-1"
              >Как Добраться:</span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="navigationText" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <option-row v-if="createDate" title="Создана:">{{ createDate }}</option-row>
    <option-row v-if="updateDate" title="Обновлена:">{{
      updateDate
    }}</option-row>

    <option-row v-if="mapUrl" title="Посмотреть на карте:"
      ><a
        :href="mapUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="icon-link"
      >
        <v-icon>mdi-open-in-new</v-icon></a
      ></option-row
    >
    <option-row v-if="venue.user_id" title="Автор:"
      ><a
        class="link"
        href="#"
        @click.stop.prevent="openDialog(venue.user_id)"
        >{{ author || venue.user_id }}</a
      ></option-row
    >
    <option-row v-if="adminLink" title="Панель администратора:"
      ><a
        :href="adminLink"
        target="_blank"
        rel="noopener noreferrer"
        class="link"
        >Перейти</a
      ></option-row
    >
    <div v-if="!isDialog">
      <a href="#" @click.prevent.stop="$emit('showMore', venue)"
        >Смотреть детали</a
      >
      площадки
    </div>

    <template v-if="isDialog">
      <!-- Контакты -->
      <info-section
        v-if="isDialog && venue.contacts?.length"
        class="mb-4"
        :total="venue.contacts.length"
        :pending="false"
        icon="mdi-tag"
        title="Контакты"
        :pages="1"
        :current-page="1"
      >
        <option-row
          v-for="contact in venue.contacts"
          :key="contact.id"
          :title="`${contactsNames[contact.type]}:`"
          ><a
            v-if="contact.type === 'email'"
            :href="`mailto:${contact.value}`"
            >{{ contact.value }}</a
          ><a
            v-else-if="contact.type === 'link' || contact.type === 'vk'"
            :href="contact.value"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >{{ contact.value }}"</a
          ><span v-else>{{ contact.value }}</span></option-row
        >
      </info-section>

      <!-- Теги -->
      <info-section
        v-if="tags.length && isDialog"
        class="mb-4"
        :total="tags.length"
        :pending="false"
        icon="mdi-tag"
        title="Теги"
        :pages="1"
        :current-page="1"
      >
        <v-chip
          v-for="item in tags"
          :key="item.tag_id"
          color="primary"
          class="mr-2 mb-2"
          dark
          small
        >
          {{ item.name || item.tag_slug }}
        </v-chip>
      </info-section>
    </template>
  </div>
</template>
<script>
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from "@/constants";
import { talentClient } from "@/api";
import InfoSection from "@/components/InfoSection";
import dayjs from "@/plugins/dayjs";
import { marked } from "marked";

export default {
  name: "VenueCard",
  components: { InfoSection },
  props: {
    venue: Object,
    isDialog: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      author: null,
      isOpenDescription: false,
      isOpenNavText: false,
    };
  },
  computed: {
    createDate() {
      if (!this.venue?.created_at) return;
      return dayjs(this.venue.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    updateDate() {
      if (!this.venue?.updated_at) return;
      return dayjs(this.venue.updated_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    contactsNames() {
      return {
        phone: "Телефон",
        email: "Email",
        link: "Ссылка",
        vk: "VK",
      };
    },
    description() {
      if (!this.venue.description) return;
      return marked(this.venue.description);
    },
    navigationText() {
      if (!this.venue.navigation_text) return;
      return marked(this.venue.navigation_text);
    },
    adminLink() {
      return `${process.env.VUE_APP_TALENT_HOST}/venue-admin/venue/v2/edit/${this.venue.id}`;
    },
    mapUrl() {
      if (!this.venue.coordinates) return "";
      const coords = this.venue.coordinates.split(",").reverse().join(",");
      return `https://yandex.ru/maps/?ll=${coords}&z=14&l=map&pt=${coords},pm2rdm`;
    },
  },
  created() {
    this.getAuthor();
  },
  methods: {
    getFullName(user) {
      if (!user) return "";
      return [user.last_name, user.first_name, user.middle_name]
        .filter(Boolean)
        .join(" ");
    },
    async getAuthor() {
      if (!this.venue.user_id) return;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${this.venue.user_id}/`,
        });
        if (data.id) {
          this.author = this.getFullName(data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    openDialog(userId) {
      if (userId) {
        this.$emit("openUserDialog", userId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-link {
  & i {
    transition: color 0.3s;
  }
  &:hover i {
    color: var(--v-anchor-base);
  }
}
.v-expansion-panel-header {
  min-height: inherit;
  width: max-content;
}
</style>
