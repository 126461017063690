<template>
  <div>
    <venues-section
      :venues="venues"
      :tags="tags"
      @setVenuesPage="setVenuesPage"
    />
  </div>
</template>
<script>
import { talentClient } from "@/api";
import { initialListingModel } from "@/utils";
import VenuesSection from "@/components/organizations/VenuesSection.vue";
const LIMIT = 40;
export default {
  name: "VenuesPage",
  components: { VenuesSection },
  data() {
    return {
      venues: initialListingModel(5),
      tags: {},
    };
  },
  activated() {
    this.getAllVenues();
    this.getTags();
  },
  methods: {
    setVenuesPage(page) {
      this.venues.page = page;
    },
    async getTags() {
      try {
        const { data } = await talentClient({
          method: "GET",
          baseURL: "/venue_api",
          url: "tag",
          params: {
            is_public: "all",
          },
        });
        if (data.length) {
          this.tags = data.reduce((acc, tag) => {
            acc[tag.slug] = tag;
            return acc;
          }, {});
        }
      } catch (error) {
        console.error(error);
      }
    },
    getVenues(params = {}) {
      return talentClient({
        method: "GET",
        baseURL: "/venue_api",
        url: "venue",
        headers: {
          "X-Role": "organization-admin",
          "X-Organization-ID": this.$route.params.orgId,
        },
        params: {
          ...params,
          organization_id: this.$route.params.orgId,
        },
      });
    },
    async getAllVenues() {
      if (this.venues.pending) return;
      this.venues.pending = true;
      this.venues.error = "";
      let results = [];

      try {
        const firstResponse = await this.getVenues({
          limit: LIMIT,
          offset: 0,
          organization_id: this.$route.params.orgId,
        });
        results = [...firstResponse.data.results];
        if (firstResponse.data.count > LIMIT) {
          const pages = Math.ceil(firstResponse.data.count / LIMIT);
          const requests = Array.from({ length: pages - 1 }, (_, idx) => {
            const page = idx + 1;
            return this.getVenues({
              limit: LIMIT,
              offset: page * LIMIT,
              organization_id: this.$route.params.orgId,
            });
          });
          const responses = await Promise.all(requests);
          const list = responses.reduce((acc, response) => {
            return [...acc, ...response.data.results];
          }, []);
          results = [...results, ...list];
        }
      } catch (error) {
        this.venues.error = error.message;
      }

      this.venues.list = results;
      this.venues.total = results.length;
      this.venues.pagesCount = Math.ceil(results.length / this.venues.limit);
      this.venues.pending = false;
    },
  },
};
</script>
