<template>
  <info-section
    class="mb-4"
    :total="venues.total"
    :pending="venues.pending"
    icon="mdi-map-marker"
    title="Площадки"
    :pages="venues.pagesCount"
    :current-page="venues.page"
    @setPage="setPage"
  >
    <venue-card
      v-for="(venue, idx) in displayedVenues"
      :key="venue.id"
      :venue="venue"
      class="item"
      :class="{ last: idx === displayedVenues.length - 1 }"
      @openUserDialog="openUserDialog"
      @showMore="openVenueDialog"
    />
    <div v-if="!venues.list.length && !venues.pending">Нет данных</div>
    <v-dialog v-model="contactDialog" max-width="600px">
      <contact-dialog
        :is-active="contactDialog"
        :talent-id="selectedUserId"
        @close="contactDialog = false"
      />
    </v-dialog>
    <v-dialog v-model="venueDialog" max-width="600px">
      <venue-dialog
        :is-active="venueDialog"
        :venue="selectedVenue"
        :tags="tags"
        @close="venueDialog = false"
        @openUserDialog="openUserDialog"
      />
    </v-dialog>
  </info-section>
</template>
<script>
import InfoSection from "@/components/InfoSection.vue";
import VenueCard from "./VenueCard.vue";
import ContactDialog from "@/components/dialogs/ContactDialog.vue";
import VenueDialog from "@/components/dialogs/VenueDialog.vue";
export default {
  name: "VenuesSection",
  components: { InfoSection, VenueCard, ContactDialog, VenueDialog },
  props: {
    venues: {
      type: Object,
      required: true,
    },
    tags: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      contactDialog: false,
      selectedUserId: null,
      venueDialog: false,
      selectedVenue: null,
    };
  },
  computed: {
    displayedVenues() {
      const { venues } = this;
      const start = (venues.page - 1) * venues.limit;
      const end = start + venues.limit;
      return venues.list.slice(start, end);
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setVenuesPage", page);
    },
    openUserDialog(userId) {
      this.selectedUserId = userId;
      this.contactDialog = true;
    },
    openVenueDialog(venue) {
      this.selectedVenue = venue;
      this.venueDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &.last {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
