<template>
  <v-card :loading="pending">
    <v-card-text class="pa-5 black--text text-body-1">
      <venue-card
        :key="key"
        :venue="currentVenue"
        :tags="mappedVenueTags"
        is-dialog
        @openUserDialog="openUserDialog"
      />
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-btn color="primary" @click="$emit('close')">Закрыть</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import VenueCard from "@/components/organizations/VenueCard.vue";
import { talentClient } from "@/api";
export default {
  name: "VenueDialog",
  components: { VenueCard },
  props: {
    venue: {
      type: Object,
    },
    isActive: {
      type: Boolean,
    },
    tags: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pending: false,
      fullVenue: null,
      venueTags: [],
      key: 1,
    };
  },
  computed: {
    currentVenue() {
      return this.fullVenue || this.venue;
    },
    mappedVenueTags() {
      if (!this.venueTags.length) return [];
      return this.venueTags.map((tag) => {
        return {
          ...tag,
          name: this.tags[tag.tag_slug]?.name || "",
        };
      });
    },
  },
  watch: {
    isActive: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getVenue();
          this.getVenueTags();
        } else {
          this.reset();
        }
      },
    },
  },

  methods: {
    openUserDialog(userId) {
      this.$emit("openUserDialog", userId);
    },
    async getVenueTags() {
      try {
        const { data } = await talentClient({
          method: "GET",
          baseURL: "/venue_api",
          url: "venue-tag",
          params: {
            venue_id: this.venue.id,
          },
        });
        if (data.results.length) {
          this.venueTags = data.results;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getVenue() {
      this.pending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          baseURL: "/venue_api",
          url: `venue/${this.venue.id}`,
          headers: {
            "X-Role": "organization-admin",
            "X-Organization-ID": this.$route.params.orgId,
          },
        });
        this.fullVenue = data;
      } catch (error) {
        console.error(error);
      }
      this.pending = false;
    },
    reset() {
      this.fullVenue = null;
      this.pending = false;
      this.venueTags = [];
      this.key += 1;
    },
  },
};
</script>
